import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Row, Spin } from 'antd';
import { RecoilRoot } from 'recoil';

import 'antd/dist/antd.css';
import '../shared/styles/globals.css';

const TheLayout = React.lazy(() => import('../containers/TheLayout'));

export default function App() {
  return (
    <RecoilRoot>      
        <HashRouter>
          <React.Suspense fallback={<Row justify='center' style={{ 'marginTop': '35%' }}><Spin size='large' /></Row>}>
            <Switch>
              <Route render={props => <TheLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </HashRouter>      
    </RecoilRoot>
  );
}
